import React from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'
import { Link } from 'gatsby'

/* objectに対応したリンクを返す */
function getLink(item) {
  const url = new URL(item.url)
  return item.object === 'category' ? (
    <Link to={url.pathname}>{item.title}</Link>
  ) : item.object === 'page' ? (
    <Link to={item.object_slug}>{item.title}</Link>
  ) : item.object === 'post' ? (
    <Link to={'/post/' + item.object_slug}>{item.title}</Link>
  ) : item.object === 'custom' ? (
    <a href={item.url}>{item.title}</a>
  ) : (
    ''
  )
}

const GlobalNav = ({ className }) => (
  <StaticQuery
    query={graphql`
      query {
        wordpressWpApiMenusMenusItems(name: { eq: "head_nav" }) {
          items {
            url
            target
            object_slug
            type_label
            title
            object
          }
          slug
        }
      }
    `}
    render={data => {
      const items = data.wordpressWpApiMenusMenusItems.items
      return (
        <nav className={className}>
          <ul>
            {items.map(item => (
              <li key={item.object_slug}>{getLink(item)}</li>
            ))}
          </ul>
        </nav>
      )
    }}
  />
)

export default GlobalNav
