import PropTypes from 'prop-types'
import React from 'react'
import { Link } from 'gatsby'
import FooterNav from './footer_nav'

const Footer = ({ siteTitle }) => (
  <footer>
    <FooterNav className="footer-nav" />
    <div className="sns-links">
      <a
        href="https://twitter.com/ikadatic"
        className="sns-link-item sns-link-item-twitter"
        target="_blank"
        rel="noopener"
      >
        <i className="icon-twitter"></i>
      </a>
      <a
        href="https://www.instagram.com/ikadatic/"
        className="sns-link-item sns-link-item-instagram"
        target="_blank"
        rel="noopener"
      >
        <i className="icon-instagram"></i>
      </a>
      <a
        href="https://www.youtube.com/channel/UC48wjDnrB9eDp5kXR4vxVuw"
        className="sns-link-item sns-link-item-youtube"
        target="_blank"
        rel="noopener"
      >
        <i className="icon-youtube"></i>
      </a>
    </div>
    <div className="site-info">©{siteTitle}</div>
  </footer>
)

Footer.propTypes = {
  siteTitle: PropTypes.string,
}

Footer.defaultProps = {
  siteTitle: ``,
}

export default Footer
