import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import React from 'react'
import GlobalNav from './global_nav'

const Header = ({ siteTitle }) => (
  <header>
    <h1 className="site-title">
      <Link to="/">{siteTitle}</Link>
    </h1>
    <GlobalNav className="global-nav" />
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
