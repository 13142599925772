/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'

function SEO({ description, lang, meta, title, url, image }) {
  const {
    wordpressSiteMetadata,
    wordpressWpUsers,
    file,
    site,
  } = useStaticQuery(
    graphql`
      query {
        wordpressSiteMetadata {
          name
          description
          url
        }
        wordpressWpUsers {
          name
        }
        file(relativePath: { eq: "og-image.png" }) {
          publicURL
        }
        site {
          siteMetadata {
            siteUrl
          }
        }
      }
    `,
  )

  const metaDescription = description || wordpressSiteMetadata.description

  const ogImage = image || site.siteMetadata.siteUrl + file.publicURL

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s | ${wordpressSiteMetadata.name}`}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:url`,
          content: url,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:image`,
          content: ogImage,
        },
        {
          property: `og:site_name`,
          content: wordpressSiteMetadata.name,
        },
        {
          property: `og:locale`,
          content: `ja_JP`,
        },
        {
          name: `twitter:card`,
          content: `summary_large_image`,
        },
        {
          name: `twitter:site`,
          content: `@` + wordpressWpUsers.name,
        },
      ].concat(meta)}
    />
  )
}

SEO.defaultProps = {
  lang: `ja`,
  meta: [],
  description: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default SEO
